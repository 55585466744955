import { FC } from "react";
import {
  HighlightImage,
  HighlightImageContainer,
  ImageHighlightWrapper,
} from "./ImageHighlight.styled";

export interface GalleryProps {
  coverImage: string;
  galerryTitle?: string;
  url?: string;
}

export interface ImageHighlightProps {
  gallery: GalleryProps[];
}

const ImageHighlight: FC<ImageHighlightProps> = ({
  gallery,
}: ImageHighlightProps) => {
  return (
    <ImageHighlightWrapper>
      {gallery?.map((item, idx) => (
        <HighlightImageContainer key={idx + item.coverImage} href={item.url}>
          <HighlightImage
            src={item.coverImage}
            onClick={() => {
              console.log(item.coverImage); // TODO: on click navigate to href
            }}
          />
        </HighlightImageContainer>
      ))}
    </ImageHighlightWrapper>
  );
};

export default ImageHighlight;
