import React, { FC } from "react";
import {
  ProductImage,
  ProductItemWrapper,
  ProductRedirectAction,
  ProductTitle,
} from "./ProductItem.styled";

export interface ProductItemProps {
  productId?: string;
  productImageUrl?: string;
  productTitle?: string;
  productPrice?: number;
  productSale?: number;
  productRedirectUrl?: string;
  productRedirectActionText?: string;
}

const ProductItem: FC<ProductItemProps> = ({
  productId,
  productImageUrl,
  productTitle,
  productRedirectUrl,
  productRedirectActionText,
}: ProductItemProps) => (
  <ProductItemWrapper
  // href={productRedirectUrl ? productRedirectUrl : `/product/${productId}`}
  >
    <ProductImage
      src={productImageUrl}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "image_path_here"; //fall back image
      }}
    />
    {productTitle && <ProductTitle>{productTitle}</ProductTitle>}
    {productRedirectUrl && productRedirectActionText && (
      <ProductRedirectAction href={productRedirectUrl}>
        {productRedirectActionText}
      </ProductRedirectAction>
    )}
  </ProductItemWrapper>
);

export default ProductItem;
