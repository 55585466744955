import styled from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import MainPage from "./pages/MainPage/Main";
import NewArrivals from "./pages/NewArrivalsPage/NewArrivals";
import ProductDetailsPage from "./pages/ProductDetailsPage/ProductDetailsPage";

const AppContainer = styled.div``;

const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<MainPage />} />
            <Route path="*" element={<MainPage />} />
            {/* <Route path="/new-arrivals" element={<NewArrivals />} />
            <Route path="/details/:productId" element={<ProductDetailsPage />} /> */}
            {/* TODO: create pageId routes to dynamically get info about created pages */}
            {/* <Route path="*" element={<h1>Not Found 404</h1>} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContainer>
  );
};

export default App;
