import styled from "styled-components";

export const ProductItemListWrapper = styled.div`
  display: flex;
  gap: var(--item-spacing-primary);

  @media (max-width: 856px) {
    flex-wrap: wrap;
  }
`;

export const ProductItemWrapper = styled.div`
  width: 100%;

  @media (max-width: 856px) {
    flex: 1 1 calc(50% - 20px);
  }
`;
