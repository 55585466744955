import React, { FC } from "react";
import { ContentBlockTitle, ContentBlockWrapper } from "./ContentBlock.styled";

interface ContentBlockProps {
  title?: string;
  headerStyle?: boolean;
  children?: any;
}

const ContentBlock: FC<ContentBlockProps> = ({
  title,
  headerStyle = false,
  children,
}: ContentBlockProps) => {
  return (
    <ContentBlockWrapper>
      <ContentBlockTitle headerStyle={headerStyle}>{title}</ContentBlockTitle>
      {children}
    </ContentBlockWrapper>
  );
};

export default ContentBlock;
