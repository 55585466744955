import styled from "styled-components";

interface ContentBlockWrapperProps {
  height?: number;
}

export const ContentBlockWrapper = styled.div<ContentBlockWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.height ? props.height + "px" : "auto")};
  margin-bottom: 5%;
`;

interface ContentBlockTitleProps {
  headerStyle?: boolean;
}

export const ContentBlockTitle = styled.span<ContentBlockTitleProps>`
  color: black;
  font-size: 20px;
  text-align: ${(props) => (props.headerStyle ? "center" : "left")};
  font-size: ${(props) => props.headerStyle && "30px"};
  font-weight: ${(props) => props.headerStyle && "500"};
  margin: ${(props) => props.headerStyle && "5% 0px"};
`;
