import styled from "styled-components";
import { Icon } from "@iconify/react";

export const NavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  padding: 10px 0px;
  z-index: 1000;
  border-bottom: 1px solid black;
  background-color: white;
  color: black;
`;

export const NavigationOffset = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 0px;
  height: 60px;
`;

export const Logo = styled.img`
  max-height: 60px;
  margin-right: 10px;
  cursor: pointer;
`;

export const NavIcon = styled(Icon)`
  margin: 0px 10px;
  font-size: 18px;
  cursor: pointer;
`;

export const NavSearch = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  & > span {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
  }
`;

export const LeftSideConteiner = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  & > a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin: 0px 10px;
  }
`;

export const LeftLinks = styled.div``;

export const UtilitiesContainer = styled.div`
  display: flex;
  align-items: center;
`;
