export const mockGallery = [
  {
    galerryTitle: "WOMEN'S COLLECTION 2024",
    coverImage:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/S_2_resized.jpg",
    url: null,
  },
  {
    galerryTitle: "MEN'S COLLECTION 2024",
    coverImage:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/W_4_resized.jpg",
  },
];

const DenimCrouselWomens: object[] = [
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Agnetha++1167.jpg",
    productTitle: "AGNETHA 1167",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Agnetha++1170.jpg",
    productTitle: "AGNETHA 1170",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Alice+1169.jpg",
    productTitle: "ALICE 1169",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Asa+1155.jpg",
    productTitle: "ASA 1155",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Asa+1158.jpg",
    productTitle: "ASA 1158",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Elsa+1156.jpg",
    productTitle: "ELSA 1156",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Elsa+1160.jpg",
    productTitle: "ELSA 1160",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Denim/Elsa+1162.jpg",
    productTitle: "ELSA 1162",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
];

const DenimCrouselMens: object[] = [
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/gustav+1174.jpg",
    productTitle: "GUSTAV 1174",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/gustav+1179.jpg",
    productTitle: "GUSTAV 1179",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/magnus+1157.jpg",
    productTitle: "MAGNUS 1157",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/magnus+1164.jpg",
    productTitle: "MAGNUS 1164",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/magnus+1166.jpg",
    productTitle: "MAGNUS 1166",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/Nils+1165.jpg",
    productTitle: "NILS 1165",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/oscar+1152.jpg",
    productTitle: "OSCAR 1152",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/oscar+1154.jpg",
    productTitle: "OSCAR 1154",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/oscar+1171.jpg",
    productTitle: "OSCAR 1171",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Denim/oscar+1173.jpg",
    productTitle: "OSCAR 1173",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
];

const HoodiesCrouselMens: object[] = [
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/half_zip_cobalt.jpg",
    productTitle: "HALF-ZIP COBALT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/half_zip_green.jpg",
    productTitle: "HALF-ZIP GREEN",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/half_zip_navy.jpg",
    productTitle: "HALF-ZIP NAVY",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/half_zip_violet.jpg",
    productTitle: "HALF-ZIP VIOLET",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/hoodie_green.jpg",
    productTitle: "HOODIE GREEN",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/hoodie_mint.jpg",
    productTitle: "HOODIE MINT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/hoodie_navy.jpg",
    productTitle: "HOODIE NAVY",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/sweat_cobalt.jpg",
    productTitle: "SWEATER COBALT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/sweat_mint.jpg",
    productTitle: "SWEATER MINT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/sweat_orange.jpg",
    productTitle: "SWEATER ORANGE",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/sweat_violet.jpg",
    productTitle: "SWEATER VIOLET",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/sweat_yellow.jpg",
    productTitle: "SWEATER YELLOW",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/zip_green.jpg",
    productTitle: "ZIPPER GREEN",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/zip_navy.jpg",
    productTitle: "ZIPPER NAVY",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/zip_orange.jpg",
    productTitle: "ZIPPER ORANGE",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/M_Hoodie/zip_yellow.jpg",
    productTitle: "ZIPPER YELLOW",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
];

const HoodiesCrouselWomens: object[] = [
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/hoodie_green.jpg",
    productTitle: "HOODIE GREEN",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/hoodie_mint.jpg",
    productTitle: "HOODIE MINT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/hoodie_navy.jpg",
    productTitle: "HOODIE NAVY",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/sweat_cobalt.jpg",
    productTitle: "SWEATER COBALT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/sweat_mint.jpg",
    productTitle: "SWEATER MINT",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/sweat_orange.jpg",
    productTitle: "SWEATER ORANGE",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/sweat_violet.jpg",
    productTitle: "SWEATER VIOLET",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/sweat_yellow.jpg",
    productTitle: "SWEATER YELLOW",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/zip_green.jpg",
    productTitle: "ZIPPER GREEN",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/zip_navy.jpg",
    productTitle: "ZIPPER NAVY",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/zip_orange.jpg",
    productTitle: "ZIPPER ORANGE",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
  {
    productImageUrl:
      "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/F_Hoodie/zip_yellow.jpg",
    productTitle: "ZIPPER YELLOW",
    productRedirectUrl: "https://www.wem.ua/",
    productRedirectActionText: "view now",
  },
];

export const mainPageLayout: any = {
  id: "faf4082b-87be-4f34-b57e-ce58231fc669",
  name: "main page",
  layout: [
    {
      type: "ContentBlock",
      title: "SPRING-SUMMER 2024 COLLECTION",
      headerStyle: true,
      childComponent: {
        type: "ImageHighlight",
        gallery: mockGallery,
      },
    },
    {
      type: "ContentBlock",
      title: "MEN'S DENIM",
      childComponent: {
        type: "Carousel",
        items: DenimCrouselMens,
      },
    },
    {
      type: "ContentBlock",
      title: "WOMEN'S DENIM",
      childComponent: {
        type: "Carousel",
        items: DenimCrouselWomens,
      },
    },
    {
      type: "ContentBlock",
      title: "MEN'S TOPS",
      childComponent: {
        type: "Carousel",
        items: HoodiesCrouselMens,
      },
    },
    {
      type: "ContentBlock",
      title: "WOMEN'S TOPS",
      childComponent: {
        type: "Carousel",
        items: HoodiesCrouselWomens,
      },
    },
  ],
};
