import styled from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  scroll-behavior: smooth;

  /* hide scrollbar */
  overflow-y: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
`;

interface CarouselItemProps {
  numberOfItems?: number;
}

export const CarouselItem = styled.div<CarouselItemProps>`
  scroll-snap-align: start;
  width: ${(props) =>
    `calc((100vw - var(--margin-primary-vw) * 2) / ${
      props.numberOfItems ?? 4
    } - 15px)`};

  @media (max-width: 856px) {
    width: calc(
      (100vw - var(--margin-primary-vw) * 2) / 2 - var(--item-spacing-primary) /
        2
    );
  }
`;

export const CarouselItemsContainer = styled.div`
  display: flex;
  gap: var(--item-spacing-primary);
`;

export const ChevronButton = styled.button`
  position: absolute;
  top: 45%;
  transform: translateY(-45%);
  background: none;
  border: 1px solid black;
  font-size: 20px;
  cursor: pointer;
  background-color: white;
  height: 56px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;

  @media (max-width: 856px) {
    display: none;
  }

  &.disabled {
    opacity: 0.5;
    border: 1px solid transparent;
  }
`;

export const LeftChevron = styled(ChevronButton)`
  left: -15px;
`;

export const RightChevron = styled(ChevronButton)`
  right: -15px;
`;
