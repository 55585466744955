import { Icon } from "@iconify/react";

import {
  LeftSideConteiner,
  LinksContainer,
  Logo,
  NavIcon,
  NavSearch,
  NavigationContainer,
  NavigationOffset,
  UtilitiesContainer,
} from "./Navigation.styled";
import { Link } from "react-router-dom";

interface NavigationProps {
  logo?: string;
  leftLinks?: any;
}

const Navigation = ({ leftLinks, logo }: NavigationProps) => {
  return (
    <>
      <NavigationOffset id={"navigationContainer"} />
      <NavigationContainer>
        <LeftSideConteiner>
          <Link to="/">
            <Logo src={logo} />
          </Link>
          <LinksContainer>{leftLinks}</LinksContainer>
        </LeftSideConteiner>
        {/* <UtilitiesContainer>
          <NavSearch>
            <NavIcon icon="heroicons-outline:search" />
          </NavSearch>
          <NavIcon icon="heroicons-outline:user" />
          <NavIcon icon="heroicons-outline:heart" />
          <NavIcon icon="heroicons-outline:shopping-cart" />
        </UtilitiesContainer> */}
      </NavigationContainer>
    </>
  );
};

export default Navigation;
