import React, { FC } from "react";
import { LayoutWrapper } from "./Layout.styled";
import { Outlet, Link } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
  return (
    <>
      <Navigation
        logo={
          "https://wem-store-media-public.s3.eu-central-1.amazonaws.com/WEM.png"
        }
        leftLinks={
          <>
            {/* <Link to="/new-arrivals">New Arivals</Link>
            <Link to="/women">Women</Link>
            <Link to="/men">Men</Link>
            <Link to="/sustainability">Sustainability</Link>
            <Link to="/about">About</Link> */}
          </>
        }
      />
      <LayoutWrapper>
        <Outlet />
      </LayoutWrapper>

      <Footer />
    </>
  );
};

export default Layout;
