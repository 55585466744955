import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import _debounce from "lodash/debounce";
import {
  CarouselContainer,
  CarouselItem,
  CarouselItemsContainer,
  LeftChevron,
  RightChevron,
} from "./Carousel.styled";

interface CarouselProps {
  items: ReactNode[];
  key?: number;
  gap?: number;
  itemsPerRow?: number;
}

export const Carousel: FC<CarouselProps> = ({
  items,
  itemsPerRow,
  key,
}: CarouselProps) => {
  const carouseContainerRef = useRef<any>(null);
  const [chevronDisabled, setChevronDisabled] = useState({
    prev: false,
    next: false,
  });
  let itemSpacing = 20;
  const container = document.querySelector(".container");

  if (container) {
    itemSpacing = parseInt(window.getComputedStyle(container).gap, itemSpacing);
  }

  const calcuateActiveChevrons = () => {
    if (!carouseContainerRef.current) return;

    const maxScrollWidth =
      carouseContainerRef.current.scrollWidth -
      carouseContainerRef.current.clientWidth;

    const disableLeft = carouseContainerRef.current.scrollLeft <= 0;
    const disableRight =
      carouseContainerRef.current.scrollLeft >= maxScrollWidth - 1;

    setChevronDisabled({
      prev: disableLeft,
      next: disableRight,
    });
  };

  useEffect(() => {
    calcuateActiveChevrons();
  }, []);

  const debouncedScrollHandler = _debounce(calcuateActiveChevrons, 150);

  const handleChevronNext = () => {
    if (!carouseContainerRef.current) return;
    if (chevronDisabled.next) return;

    const carouselItemWidth =
      carouseContainerRef.current.children[0]?.children[0]?.clientWidth +
      itemSpacing;

    carouseContainerRef.current.scrollLeft += carouselItemWidth;
  };

  const handleChevronPrev = () => {
    if (!carouseContainerRef.current) return;
    if (chevronDisabled.prev) return;

    const carouselItemWidth =
      carouseContainerRef.current.children[0]?.children[0]?.clientWidth +
      itemSpacing;

    carouseContainerRef.current.scrollLeft -= carouselItemWidth;
  };

  return (
    <CarouselContainer
      ref={carouseContainerRef}
      onScroll={debouncedScrollHandler}
    >
      <CarouselItemsContainer id={"carouselItemContainer"}>
        {items!.map((item, idx) => {
          return (
            <CarouselItem numberOfItems={itemsPerRow} key={key ?? "" + idx}>
              {item}
            </CarouselItem>
          );
        })}
        <LeftChevron
          className={chevronDisabled.prev ? "disabled" : ""}
          onClick={handleChevronPrev}
        >
          &#8249;
        </LeftChevron>
        <RightChevron
          className={chevronDisabled.next ? "disabled" : ""}
          onClick={handleChevronNext}
        >
          &#8250;
        </RightChevron>
      </CarouselItemsContainer>
    </CarouselContainer>
  );
};
