import styled from "styled-components";

export const FooterWrapper = styled.footer`
  border-top: 1px solid var(--wem-black);
  display: flex;
  justify-content: space-evenly;
  padding: 25px;
  flex-wrap: wrap;
`;

export const FooterTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 25px;
`;

export const IconLink = styled.a`
  text-decoration: none;
  color: inherit;
  & > svg {
    height: 40px;
    width: 40px;
    cursor: pointer;
    &:hover {
      transform: 1s ease-in;
      color: grey;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 25px;
`;

export const EmailButton = styled.a`
  text-decoration: none;
  border: 1px solid var(--wem-black);
  padding: 10px;
  color: var(--wem-black);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: 1s ease-in;
    color: grey;
    border-color: grey;
  }
  &.no-border {
    border: none;
    text-decoration: underline;
    & > svg {
      margin-right: 5px;
      height: 24px;
      width: 24px;
    }
  }
`;
