import styled from "styled-components";

export const ImageHighlightWrapper = styled.div`
  display: flex;
  gap: var(--item-spacing-primary);
  overflow: hidden;
  width: 100%;

  @media (max-width: 856px) {
    flex-wrap: wrap;
  }
`;

export const HighlightImage = styled.img`
  width: 100%;
  height: auto;
`;

export const HighlightImageContainer = styled.a`
  width: 100%;
  cursor: pointer;
`;
