import React, { FC } from "react";
import {
  ProductItemListWrapper,
  ProductItemWrapper,
} from "./ProductItemList.styled";
import ProductItem, { ProductItemProps } from "../ProductItem/ProductItem";

interface ProductItemListProps {
  productItems: ProductItemProps[];
}

const ProductItemList: FC<ProductItemListProps> = ({
  productItems,
}: ProductItemListProps) => {
  return (
    <ProductItemListWrapper>
      {productItems.slice(0, 4).map((item, index) => (
        <ProductItemWrapper key={index + item.productImageUrl!}>
          <ProductItem {...item} />
        </ProductItemWrapper>
      ))}
    </ProductItemListWrapper>
  );
};

export default ProductItemList;
