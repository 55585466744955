import { FC } from "react";
import {
  ColumnContainer,
  FooterWrapper,
  IconContainer,
  IconLink,
  EmailButton,
  FooterTitleWrapper,
} from "./Footer.styled";
import { Icon } from "@iconify/react";

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <FooterWrapper>
    <ColumnContainer>
      <FooterTitleWrapper>
        <h3>Follow Us</h3>
      </FooterTitleWrapper>
      <IconContainer>
        <IconLink href="https://www.instagram.com/wemdenim/" target="__blank">
          <Icon icon="mdi:instagram" />
        </IconLink>
        <IconLink href="https://www.tiktok.com/@wemdenim" target="__blank">
          <Icon icon="ic:baseline-tiktok" />
        </IconLink>
        <IconLink href="https://www.facebook.com/www.wem.ua/" target="__blank">
          <Icon icon="ic:baseline-facebook" />
        </IconLink>
      </IconContainer>
    </ColumnContainer>
    <ColumnContainer>
      <FooterTitleWrapper>
        <h3>Store</h3>
      </FooterTitleWrapper>
      <EmailButton
        className={"no-border"}
        href="https://www.wem.ua/"
        target="__blank"
      >
        <Icon icon="ic:outline-shopping-bag" />
        www.wem.ua
      </EmailButton>
    </ColumnContainer>
    <ColumnContainer>
      <FooterTitleWrapper>
        <h3>Contact Us</h3>
      </FooterTitleWrapper>
      <EmailButton href="mailto:info@wemstyle.se">info@wemstyle.se</EmailButton>
    </ColumnContainer>
  </FooterWrapper>
);

export default Footer;
