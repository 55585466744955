import { FC, useEffect, useState } from "react";
import { MainWrapper } from "./Main.styled";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import ImageHighlight from "../../components/ImageHighlight/ImageHighlight";
import ProductItemList from "../../components/ProductItemList/ProductItemList";
import ProductItem, {
  ProductItemProps,
} from "../../components/ProductItem/ProductItem";
import { Carousel } from "../../components/Carousel/Carousel";
import {
  BaseComponentProps,
  CarouselComponent,
  ContentBlockComponent,
  ImageHighlightComponent,
  ProductItemListComponent,
} from "../../types/componentTypes";
import React from "react";
import { mainPageLayout } from "./MainPageLayout_tmp";

interface MainPageProps {}

const MainPage: FC<MainPageProps> = () => {
  const [data, setData] = useState<ContentBlockComponent[] | null>(null);

  useEffect(() => {
    setData(mainPageLayout.layout);
  }, []);

  // TODO: extract it to the page renderer, and pass in the JSON data as params
  const renderChildComponent = (
    childComponent: BaseComponentProps,
    key: number
  ) => {
    switch (childComponent.type) {
      case "ImageHighlight":
        return (
          <ImageHighlight {...(childComponent as ImageHighlightComponent)} />
        );
      case "Carousel":
        const items = (childComponent as CarouselComponent).items;
        return (
          <Carousel
            items={items.map((item) => (
              <ProductItem {...item} />
            ))}
          />
        );
      case "ProductItemList":
        return (
          <ProductItemList {...(childComponent as ProductItemListComponent)} />
        );
      default:
        return null;
    }
  };

  return (
    <MainWrapper>
      {data &&
        data.map((item, index) => (
          <React.Fragment key={index}>
            {item.type === "ContentBlock" ? (
              <ContentBlock {...(item as ContentBlockComponent)}>
                {item.childComponent &&
                  renderChildComponent(item.childComponent, index)}
              </ContentBlock>
            ) : (
              renderChildComponent(item as BaseComponentProps, index)
            )}
          </React.Fragment>
        ))}
    </MainWrapper>
  );
};

export default MainPage;
