import styled from "styled-components";

export const ProductItemWrapper = styled.span`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  text-decoration: none;
`;

export const ProductImage = styled.img`
  width: 100%;
`;

export const ProductTitle = styled.span`
  color: #1b1b1b;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 15px;
`;

export const ProductRedirectAction = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: #1b1b1b;
  &:hover {
    transform: 1s ease-in;
    color: grey;
  }
`;
